@import "./custom.scss";

body {
    background-color: theme-color("background") !important;
    color: theme-color("text") !important;
}

a {
    color: theme-color("primary");
}

a:hover {
    color: theme-color("primary-hover") !important;
}

.App {
    text-align: center;
}

p {
    text-align: left;
}

.nav-bar-background {
    background-color: theme-color("background-secondary");
}

.nav-bar {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

.nav-link {
    color: theme-color("primary");
}

.nav-link:hover {
    color: theme-color("primary-hover");
}

.card-link {
    color: theme-color("primary");
}

.card-link:hover {
    color: theme-color("primary-hover");
}

.social-media-icons-row {
    margin-top: 10px;
    margin-bottom: 10px;
}

.social-media-icon {
    margin-left: 15px;
    margin-right: 15px;
    filter: invert(100%);
}

.main-section {
    margin-top: 50px;
}

.footer {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: theme-color("background-secondary");
    width: 100%;

    a {
        color: theme-color("primary") !important;
    }
    
    a:hover {
        color: theme-color("primary-hover") !important;
    }
}

@media only screen and (max-width: 576px) {
    .medium-widget-article__row {
        flex-direction: column !important;
    }
}

@media only screen and (max-width: 768px) {
    .content {
        margin-left: 20px;
        margin-right: 20px;
    }

    .cover {
        width: 100%;
        height: 100%;
    }

    .profile {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (min-width: 768px) {
    .App {
        margin-left: 5%;
        margin-right: 5%;
    }
}

@media only screen and (min-width: 992px) {

    .nav-bar {
        width: 794px;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 1200px) {

    .App {
        width: 1080px;
        margin: 0 auto;
    }
}

.cover {
    max-height: 100%;   /* Change to content size */
    max-width: 100%;
}

.profile {
    max-height: 200px;
    max-width: 200px;
}

.projects-container {
    max-width: 100% !important;
}

.projects-card {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: theme-color("background-secondary") !important;
}

.blog-container {
    margin-top: 20px;
    max-width: 100% !important;
}

.blog-posts-list {
    text-align: left;
}

.blog-link:hover {
    text-decoration: none;
}

.blog-card {
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none;
    background-color: theme-color("background-secondary") !important;
}

.blog-image {
    aspect-ratio: 16 / 10;
    object-fit: cover;
}
